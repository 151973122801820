import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const AddUserForm = ({ setShowModal }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const addUser = (formData) => {
    console.log(formData);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center border-b py-2">
        <span className="font-bold text-2xl">{t("add_personnel")}</span>
        <AiOutlineClose
          size={24}
          className="text-gray-400 cursor-pointer"
          strokeWidth={50}
          onClick={() => setShowModal(false)}
        />
      </div>
      <div className="py-2">
        <form className="flex flex-col gap-1" onSubmit={handleSubmit(addUser)}>
          <label className="mt-2" htmlFor="fullname">
            {t("name")} {t("surname")}
          </label>
          <input
            type="text"
            name="fullname"
            id="fullname"
            className="border-b border-gray-200 outline-none focus:border-gray-600 transition-colors"
            placeholder="Name Surname"
            {...register("fullname", {
              required: "Name Surname is required",
            })}
          />
          {errors.fullname && (
            <div className="text-sm text-danger">
              {errors.fullname?.message}
            </div>
          )}
          <label className="mt-2" htmlFor="email">
            {t("email")}
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="border-b border-gray-200 outline-none focus:border-gray-600 transition-colors"
            placeholder="Email adress"
            {...register("email", { required: "Email is required" })}
          />
          {errors.email && (
            <div className="text-sm text-danger">{errors.email?.message}</div>
          )}
          <label className="mt-2" htmlFor="phone">
            {t("phone")}
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            className="border-b border-gray-200 outline-none focus:border-gray-600 transition-colors"
            placeholder="Phone"
            {...register("phone", { required: "Phone is required" })}
          />
          {errors.phone && (
            <div className="text-sm text-danger">{errors.phone?.message}</div>
          )}
          <div className="flex flex-row justify-end items-center gap-2 mt-2">
            <button
              onClick={() => setShowModal(false)}
              className="bg-danger py-2 px-4 rounded-md text-white font-bold"
            >
              {t("close")}
            </button>
            <button
              type="submit"
              className="bg-green py-2 px-4 rounded-md text-white font-bold"
            >
              {t("save")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddUserForm;
