import React, { useEffect } from "react";

import Accordion from "../../components/Accordion";
import { useStore } from "../../store/useStore";
import Loading from "../../components/Loading";

const NeedHelp = () => {
  const needHelp = useStore((state) => state.needHelp);
  const fetchNeedHelp = useStore((state) => state.fetchNeedHelp);

  useEffect(() => {
    fetchNeedHelp();
  }, [fetchNeedHelp]);

  if (needHelp.isLoading) {
    return <Loading />;
  }
  return (
    <div className="container my-8">
      {needHelp.data.map((help) => {
        return <Accordion data={help} key={help.id} />;
      })}
    </div>
  );
};

export default NeedHelp;
