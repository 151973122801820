import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import ProgressbarWithPP from "./ProgressbarWithPP";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";
import { useStore } from "../store/useStore";

const ProfileCard = () => {
  const { t } = useTranslation();
  const user = useStore((state) => state.user);
  const userInfo = useStore((state) => state.userInfo);
  if (user.isLoading || userInfo.isLoading) {
    return <Loading />;
  }
  return (
    <div className=" w-full flex flex-col justify-center items-center bg-opacity-5 backdrop-blur-[2px] bg-white border-[1px] border-gray-400 border-opacity-20">
      <h5 className="uppercase w-full text-xl py-4 font-bold bg-dblue text-center text-white">
        {t("profile")}
      </h5>
      <div className="w-full flex flex-col gap-3 justify-center items-center p-6">
        <div className="w-[255px]">
          <ProgressbarWithPP user={userInfo.data} />
        </div>
        {userInfo.data.metaCVComletionRate ? (
          <>
            <span className="text-black">{t("completion_rate")}</span>
            <span className="text-green text-4xl font-bold">
              {userInfo.data.metaCVComletionRate} %
            </span>
          </>
        ) : null}
        <div className="text-black flex flex-col w-full divide-y-[1px] border-y-[1px]">
          {userInfo.data.metaCv.map((row, index) => {
            return (
              <div key={index} className="flex flex-row justify-between py-4">
                <span>{row.title}</span>
                <span className="font-bold">{row.rate}</span>
              </div>
            );
          })}
        </div>
        {user.data.userType === 1 ? (
          <Link
            to="/cv"
            className="bg-dblue rounded-md text-white text-xl font-bold p-5 w-full flex items-center justify-center gap-3"
            target="_blank"
          >
            {t("my_meta_cv")}
            <AiOutlineArrowRight strokeWidth={60} />
          </Link>
        ) : null}
      </div>
    </div>
  );
};

export default ProfileCard;
