import React, { useState } from "react";
import { AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";

const Accordion = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-full even:bg-gray-100 p-4">
      <div
        className="flex flex-row items-center gap-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? (
          <AiOutlineMinusSquare size={24} className="text-gray-400" />
        ) : (
          <AiOutlinePlusSquare size={24} className="text-gray-400" />
        )}
        <h5 className={`${isOpen ? "font-bold" : null}`}>{data.title}</h5>
      </div>
      <div
        className={`overflow-scroll transition-[max-height] duration-300 ease-in-out scrollbar-hide ${
          isOpen ? "max-h-[150px]" : "max-h-0"
        }`}
      >
        <p className={`mt-4`}>{data.content}</p>
      </div>
    </div>
  );
};

export default Accordion;
