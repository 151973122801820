import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import CandidateCard from "./CandidateCard";
import { FaChevronDown } from "react-icons/fa";
import { USER_TABLE_DISPLAY_LIMIT } from "../pages/dashboard/constants";
import { useStore } from "../store/useStore";
import { useTranslation } from "react-i18next";

const SearchList = ({ searchResult, searchStr }) => {
  const { t } = useTranslation();
  const [filteredMetaCvs, setFilteredMetaCvs] = useState([]);
  const handleFavMetaCv = useStore((state) => state.handleFavMetaCv);

  // Handle add&remove fav service
  const handleFavourite = (id, isFav) => {
    // handleFavourite(user, searchResult, setSearchResult, id, isFav);
    handleFavMetaCv(id, isFav);
  };

  //load more logic
  const [index, setIndex] = useState(USER_TABLE_DISPLAY_LIMIT);
  const [isCompleted, setIsCompleted] = useState(false);
  const loadMore = () => {
    setIndex(index + USER_TABLE_DISPLAY_LIMIT);
    if (index + USER_TABLE_DISPLAY_LIMIT >= searchResult.data.length) {
      setIsCompleted(true);
    } else {
      setIsCompleted(false);
    }
  };
  //load more
  useEffect(() => {
    if (searchResult.isLoaded) {
      let filtered = [...searchResult.data];
      filtered = filtered.slice(0, index);
      setFilteredMetaCvs(filtered);
    }
  }, [searchResult, index]);

  if (searchResult.isLoading) {
    return <Loading />;
  }
  return (
    <div className="flex flex-col gap-2">
      <p className="pt-2">
        {t("results_matching", {
          search_str: `${searchStr}`,
          list_length: `${searchResult.data.length}`,
        })}
      </p>
      {filteredMetaCvs.map((metacv) => {
        return (
          <CandidateCard
            key={metacv.id}
            metacv={metacv}
            handleFav={handleFavourite}
          />
        );
      })}
      {/* LOAD MORE */}
      {!isCompleted && (
        <button
          onClick={loadMore}
          className="bg-dblue text-white p-5 font-bold uppercase"
        >
          {t("show_more")}
          <FaChevronDown className="inline ml-2" />
        </button>
      )}
    </div>
  );
};

export default SearchList;
