import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineSearch } from "react-icons/ai";

import Filterbar from "../../components/Filterbar";
import SearchList from "../../components/SearchList";
import { useStore } from "../../store/useStore";

const Searchcv = () => {
  const { t } = useTranslation();
  const searchResult = useStore((state) => state.searchResult);
  const filters = useStore((state) => state.filters);
  const fetchFilters = useStore((state) => state.fetchFilters);
  const fetchSearchResult = useStore((state) => state.fetchSearchResult);
  const [selectedFilters, setSelectedFilters] = useState({
    search: [],
    location: [],
    operation: [],
    working_status: [],
    language: [],
    education: [],
    professional_order: [],
  });
  // const [showFilterBar, setShowFilterBar] = useState(false);

  const handleStrSearch = (e) => {
    setSelectedFilters((prev) => ({
      ...prev,
      search: [e.target.value],
    }));
  };

  // fetch all filters
  useEffect(() => {
    fetchFilters();
  }, [fetchFilters]);

  //fetch filtered users
  useEffect(() => {
    let transformedFilters = {};
    for (const key in selectedFilters) {
      if (selectedFilters[key].length <= 0 && key !== "search") {
        transformedFilters[key] = "0";
      } else {
        transformedFilters[key] = selectedFilters[key].join();
      }
    }

    fetchSearchResult([transformedFilters]);
  }, [selectedFilters, fetchSearchResult]);

  // const openFilterBar = () => {
  //   setShowFilterBar(true);
  //   if (typeof window !== "undefined" && window.document) {
  //     document.body.style.overflow = "hidden";
  //   }
  // };
  // const closeFilterBar = () => {
  //   setShowFilterBar(false);
  //   document.body.style.overflow = "unset";
  // };

  return (
    <div className="container flex gap-6 max-lg:flex-col max-lg:relative">
      {/*FILTER BAR*/}
      {/* COLLAPSIBLE FILTER'S SIDEBAR >> issue: cannot scroll filterbar! */}
      {/* <div
        className={`max-lg:p-4 max-lg:fixed max-lg:w-full md:w-1/5 max-lg:z-10 transition-all max-lg:bg-white ${
          showFilterBar
            ? "max-lg:left-0 max-lg:-translate-x-[0%]"
            : "max-lg:left-0 max-lg:translate-x-[100%]"
        }`}
      >
        <div className="flex flex-row justify-between items-center">
          <p className="max-lg:block hidden text-xl">Filter Meta CVs by</p>
          <button
            className="max-lg:block hidden ml-auto font-bold text-gray-400 px-4"
            onClick={() => closeFilterBar()}
          >
            <AiOutlineClose size={24} strokeWidth={50} />
          </button>
        </div> */}

      <div className="w-1/4 max-lg:w-full">
        <Filterbar
          loading={filters.isLoading}
          filters={filters.data}
          setSelectedFilters={setSelectedFilters}
          selectedFilters={selectedFilters}
        />
      </div>

      {/* </div> */}
      <div className="flex flex-col w-3/4 max-lg:w-full py-4">
        {/*SEARCH BAR*/}
        <div className="flex">
          <input
            type="search"
            placeholder={`${t("title_skill_search")}`}
            className="w-full border py-4 px-6"
            onChange={handleStrSearch}
            value={selectedFilters.search}
          />
          {/* <button
            onClick={() => openFilterBar()}
            className="p-4 font-bold bg-gray-100 border-r max-lg:block hidden"
          >
            <RiListSettingsFill className="text-xl" />
          </button> */}
          <button className="p-4 font-bold bg-gray-100">
            <AiOutlineSearch className="text-xl" />
          </button>
        </div>

        {/* SEARCH RESULTS */}
        <SearchList
          searchResult={searchResult}
          searchStr={selectedFilters.search}
        />
      </div>
    </div>
  );
};

export default Searchcv;
