import React, { useEffect } from "react";
import OrientationCard from "../../components/OrientationCard";
import { useStore } from "../../store/useStore";
import { HiOutlineDownload } from "react-icons/hi";
import Loading from "../../components/Loading";

const Mymetacv = () => {
  const fetchFortune = useStore((state) => state.fetchFortune);
  const user = useStore((state) => state.user);
  const fortune = useStore((state) => state.fortune);

  useEffect(() => {
    fetchFortune();
  }, [fetchFortune]);

  if (fortune.isLoading) {
    return <Loading />;
  }

  return (
    <div className="container">
      {/* Meta CV Header */}
      <div className="flex py-4 font-bold text-4xl">
        <div className="flex flex-col flex-1 justify-center items-center ">
          <span className="max-lg:text-4xl">{user.data.userName}</span>
          <span className="max-lg:text-2xl">Meta CV</span>
        </div>
        <button className="p-1 max-sm:hidden">
          <HiOutlineDownload />
        </button>
      </div>
      {/* Job Orientations */}
      <div className="flex gap-5 flex-col">
        {fortune.data.map((row, index) => (
          <OrientationCard key={index} data={row} loading={fortune.isLoading} />
        ))}
      </div>
    </div>
  );
};

export default Mymetacv;
