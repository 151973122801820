import axios from "axios";

import { ENDPOINTS, BASE_URL } from "./constants";

const getRequestObject = ({ requestType, payload = null, params = null }) => {
  const { path, method, isAuthenticated } = ENDPOINTS[requestType];

  const url = `${BASE_URL}${path}`;

  const requestConfig = {
    method: method,
    url,
    params,
  };

  if (isAuthenticated) {
    const token = localStorage.getItem("token");
    requestConfig.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  if (payload) {
    requestConfig.data = payload;
  }

  const requestObject = axios(requestConfig);

  return requestObject;
};

const handleFailure = (err, onError) => {
  console.log("request err", err);
  // TODO: handle generic failure by toasters and a console log
  if (onError) onError(err.message);
};

const isRequestSuccess = (responseData) => {
  // TODO: Check api specific failure conditions
  return true;
};

export const sendApiRequest = async (
  options,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  onLoading(true);
  const awaitableRequestObject = getRequestObject(options);
  try {
    const response = await awaitableRequestObject;
    const data = response.data;
    if (isRequestSuccess(data)) {
      if (onSuccess) onSuccess(data);
    } else {
      handleFailure(null, onError);
    }
  } catch (err) {
    handleFailure(err, onError);
  } finally {
    onLoading(false);
  }
};

export const sendParallelApiRequests = async (
  optionsList,
  onSuccess = null,
  onError = null
) => {
  const requestObjects = optionsList.map((item) => getRequestObject(item));
  axios
    .all(requestObjects)
    .then(
      axios.spread((...responses) => {
        console.log("res>>", responses);
        const failed = responses.some((res) => !isRequestSuccess(res.data));
        if (failed) {
          handleFailure(null, onError);
        } else {
          console.log("responses", responses);
          const responsesData = responses.map((res) => res.data);
          if (onSuccess) onSuccess(responsesData);
        }
      })
    )
    .catch((err) => {
      console.log("err", err);
      handleFailure(err);
    });
};
