import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Menu from "./Menu";
import { FaPowerOff } from "react-icons/fa";
import { NAV_TABS } from "../pages/dashboard/constants";

import ChartsCarousel from "./ChartsCarousel";
import { useDocumentTitle } from "../hooks/useDocumentTitle";
import { AiOutlineClose, AiTwotoneSetting } from "react-icons/ai";
import Modal from "./Modal";
import ProgressbarWithPP from "./ProgressbarWithPP";
import { useTranslation } from "react-i18next";

const Header = ({ user, userInfo, logout }) => {
  const {
    i18n: { changeLanguage, resolvedLanguage },
    t,
  } = useTranslation();

  const { pathname } = useLocation();

  const [docTitle, setDocTitle] = useDocumentTitle("Overview");

  const [isScrolled, setIsScrolled] = useState(false);

  const [lastScrollY, setLastScrollY] = useState(45);
  const [showTopBar, setShowTopBar] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState(resolvedLanguage);

  const isDashboardPage = pathname === "/overview";

  const isChartAvailable = user.userType === 1;

  const handleLanguageChange = () => {
    const newLanguage = currentLanguage === "en" ? "tr" : "en";
    setCurrentLanguage(newLanguage);
    changeLanguage(newLanguage);
  };

  useEffect(() => {
    const controlHeader = () => {
      setIsScrolled(window.scrollY > 45); //When scroll down 45px, set isScrolled true which shrinks header
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setShowTopBar(false);
      } else {
        // if scroll up show the navbar
        setShowTopBar(true);
      }
      if (window.scrollY > 45) {
        setLastScrollY(window.scrollY); // remember current page location to use in the next move
      }
    };
    window.addEventListener("scroll", controlHeader, { passive: true });

    return () => window.removeEventListener("scroll", controlHeader);
  }, [lastScrollY]);

  return (
    <>
      <div className={`text-white relative`}>
        <div
          className={`container-full fixed top-0 right-0 z-30 bg-dblue transition-all duration-500 ease-linear max-lg:pb-1 bg-[url(https://humanas.io/humanasPanelBeta/assets/img/header-bg.png)] bg-cover bg-center ${
            isScrolled && "shadow-xl shadow-[#00000040]"
          }`}
        >
          {/* TOP BAR */}
          <div
            className={`transition-all duration-500 ease-out flex items-center justify-between  ${
              isScrolled ? "py-8" : "py-12"
            } ${
              showTopBar
                ? "max-lg:max-h-[150px] max-lg:py-6"
                : "max-lg:max-h-0 overflow-hidden max-lg:py-0"
            } `}
          >
            <Link to="/overview">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1623 417.55"
                className={`transition-all duration-500 ease-out ${
                  isScrolled ? "h-[35px]" : "h-[40px]"
                } max-lg:h-[25px] fill-white`}
              >
                <g
                  id="Group_2"
                  data-name="Group 2"
                  transform="translate(-149 -273)"
                >
                  <g id="b" transform="translate(149 391.05)">
                    <g id="c">
                      <g id="Group_1" data-name="Group 1">
                        <path
                          id="Path_1"
                          data-name="Path 1"
                          d="M506.9,48.4,467.6,9.1a31.264,31.264,0,0,0-44.2,0L384.1,48.4l32.1,32.1,29.3-29.3,29.3,29.3,32.1-32.1Z"
                        />
                        <path
                          id="Path_2"
                          data-name="Path 2"
                          d="M677.9,121.4H488.1a31.24,31.24,0,0,0-31.2,31.2V299.4h45.5V166.9h58v83.4h45.5V166.9h58V299.5h45.5V152.7a31.714,31.714,0,0,0-31.5-31.3Z"
                        />
                        <path
                          id="Path_3"
                          data-name="Path 3"
                          d="M1134.8,121.4H991.5a31.24,31.24,0,0,0-31.2,31.2V299.4h45.5V166.9h114.8V299.5H1166V152.7a31.262,31.262,0,0,0-31.2-31.3Z"
                        />
                        <path
                          id="Path_4"
                          data-name="Path 4"
                          d="M388.7,254H273.9V121.4H228.4v178H434.1v-178H388.7Z"
                        />
                        <path
                          id="Path_5"
                          data-name="Path 5"
                          d="M174.5,121.4H45.5V0H0V299.4H45.5V166.9H160.3V299.5h45.4V152.7a31.262,31.262,0,0,0-31.2-31.3Z"
                        />
                        <path
                          id="Path_6"
                          data-name="Path 6"
                          d="M1622.8,176.2V152.7a31.24,31.24,0,0,0-31.2-31.2H1448.4a31.24,31.24,0,0,0-31.2,31.2v36.6a31.168,31.168,0,0,0,28.6,31.1l131.7,16V254H1462.7V239.9h-45.4v59.5H1623V223.8a31.168,31.168,0,0,0-28.6-31.1l-131.7-16v-9.8h114.8v9.3Z"
                        />
                        <path
                          id="Path_7"
                          data-name="Path 7"
                          d="M906.3,121.4H763.1a31.24,31.24,0,0,0-31.2,31.2v23.5h45.5v-9.3H892.2v17.8h-129A31.24,31.24,0,0,0,732,215.8v83.6H937.7V152.7a31.5,31.5,0,0,0-31.4-31.3ZM892.1,254H777.3V230.1H892.1Z"
                        />
                        <path
                          id="Path_8"
                          data-name="Path 8"
                          d="M1363.2,121.4H1220a31.24,31.24,0,0,0-31.2,31.2v23.5h45.4v-9.3H1349v17.8H1220a31.24,31.24,0,0,0-31.2,31.2v83.6h205.7V152.7a31.411,31.411,0,0,0-31.3-31.3ZM1349,254H1234.2V230.1H1349V254Z"
                        />
                      </g>
                    </g>
                  </g>
                  <g
                    id="Group_3"
                    data-name="Group 3"
                    transform="translate(1203.375 -43.208)"
                    fill="#e3e3e3"
                  >
                    <path
                      id="Path_9"
                      data-name="Path 9"
                      d="M193.443,398.077q-10.6,0-14.56,9.639a36.181,36.181,0,0,0-2.051,13.262q0,9.57,4.238,15.859t12.92,6.289a13.373,13.373,0,0,0,11.69-6.084q4.169-6.084,4.17-16a36.067,36.067,0,0,0-2.051-13.125Q203.9,398.077,193.443,398.077Z"
                      fill="#e3e3e3"
                    />
                    <path
                      id="Path_10"
                      data-name="Path 10"
                      d="M273.629,396.778q-6.768,0-10.493,4.239T258.453,412.5h30.283q-.479-7.724-4.682-11.724A14.543,14.543,0,0,0,273.629,396.778Z"
                      fill="#e3e3e3"
                    />
                    <path
                      id="Path_11"
                      data-name="Path 11"
                      d="M473.125,316.208h-351a95.5,95.5,0,0,0,0,191h351a95.5,95.5,0,0,0,0-191ZM222.086,447.774q-8.2,11.418-22.9,11.416-9.229,0-14.834-3.691a29.609,29.609,0,0,1-7.247-7.656v9.365H158.033V356.583h19.414V392.4a28.422,28.422,0,0,1,8.135-7.929q5.264-3.418,13.4-3.418,14.7,0,23,10.6t8.306,27.344Q230.289,436.359,222.086,447.774Zm86.885-22.421h-51q.409,11.211,7.041,15.722a16.575,16.575,0,0,0,9.707,2.8,13.971,13.971,0,0,0,9.775-3.418,16.251,16.251,0,0,0,3.623-5.127h19.893q-.753,6.631-6.9,13.467-9.57,10.869-26.8,10.869a37.783,37.783,0,0,1-25.087-9.16q-10.869-9.16-10.87-29.8,0-19.344,9.81-29.668t25.464-10.322a39.058,39.058,0,0,1,16.748,3.486,29.349,29.349,0,0,1,12.3,11.006,37.566,37.566,0,0,1,5.673,15.381A99.38,99.38,0,0,1,308.971,425.353Zm47.236-28.1h-12.1v39.375q0,4.581,1.163,5.708t7.109,1.128q.888,0,1.88-.034t1.948-.1v14.561l-9.228.341q-13.811.479-18.868-4.785-3.28-3.348-3.281-10.322V397.257H314.439V383.38H324.83V362.6h19.277V383.38h12.1Zm77.246,59.951H412.262a21.668,21.668,0,0,1-1.231-4.238q-.342-1.983-.547-4.512a40.031,40.031,0,0,1-9.3,7.451,27.967,27.967,0,0,1-14.218,3.623q-10.117,0-16.714-5.776t-6.6-16.372q0-13.74,10.6-19.893,5.81-3.348,17.09-4.785l6.631-.82a30.417,30.417,0,0,0,7.724-1.709q4.17-1.777,4.17-5.537,0-4.58-3.179-6.324t-9.331-1.743q-6.9,0-9.775,3.418a14.2,14.2,0,0,0-2.734,6.836h-18.8q.615-9.774,5.469-16.064,7.724-9.844,26.523-9.844a47.133,47.133,0,0,1,21.738,4.853q9.5,4.855,9.5,18.321v34.179q0,3.557.137,8.614.205,3.828,1.162,5.2a6.977,6.977,0,0,0,2.871,2.256Z"
                      fill="#e3e3e3"
                    />
                    <path
                      id="Path_12"
                      data-name="Path 12"
                      d="M400.914,424.19l-4.375.821q-6.153,1.094-8.818,2.666a8.935,8.935,0,0,0-4.512,8.271q0,4.99,2.8,7.178a10.176,10.176,0,0,0,6.7,2.256A19.845,19.845,0,0,0,404.3,441.69q5.3-3.691,5.5-13.466v-7.246a21.143,21.143,0,0,1-3.726,1.879A31.1,31.1,0,0,1,400.914,424.19Z"
                      fill="#e3e3e3"
                    />
                  </g>
                </g>
              </svg>
            </Link>
            {/* MENU */}
            <div className="flex items-center gap-4">
              <button
                className="text-dblue bg-white hover:text-danger transition-all px-2 text-xl uppercase"
                onClick={handleLanguageChange}
              >
                {currentLanguage === "tr" ? "en" : "tr"}
              </button>
              <Menu />
            </div>
          </div>

          <div
            className={`flex flex-row justify-between items-center border-y-[1px] border-y-gray-400 border-opacity-30 overflow-x-hidden ${
              !showTopBar && "max-lg:border-t-0"
            }`}
          >
            <div className="flex flex-col overflow-hidden">
              <div className=" text-white">
                <div
                  className={` flex justify-between sticky transition-all duration-500 ease-out ${
                    isScrolled ? "pt-0 max-lg:py-2" : "pt-14 max-lg:py-4"
                  }`}
                >
                  {/* WELCOMING TEXT */}
                  <div
                    className={`flex flex-col transition-all duration-300 ease-out ${
                      isScrolled
                        ? "invisible opacity-0 max-h-0"
                        : "visible opacity-100 max-h-100"
                    } `}
                  >
                    <span
                      id="welcoming"
                      className="text-5xl font-medium d-flex pb-2 max-lg:pb-0 max-lg:text-2xl"
                    >
                      {t("hello")}, {user.data.userName}
                    </span>
                    <span
                      id="title"
                      className="text-gray-500 text-xl max-lg:text-sm flex flex-row items-center gap-2"
                    >
                      {user.data.userTitle}
                      <Link
                        to="/joinform"
                        className="bg-[#ffffff2c] rounded-md p-1"
                      >
                        <AiTwotoneSetting className="mx-auto text-white" />
                      </Link>
                    </span>
                  </div>
                </div>
                {/* TABS */}
                <nav className="font-bold text-center text-white">
                  <ul
                    className={`pt-8 max-lg:pt-0 flex gap-12 scrollbar-hide overflow-x-scroll`}
                  >
                    {NAV_TABS.filter((tab) =>
                      tab.for.includes(user.data.userType)
                    ).map((tab, index) => {
                      return (
                        <li
                          key={index}
                          className="relative flex whitespace-nowrap group text-lg"
                        >
                          <NavLink
                            to={tab.path}
                            style={({ isActive, isPending }) => {
                              return {
                                // color: isActive ? "orange" : "white",
                                borderColor: isActive ? "orange" : "",
                                borderBottom: isActive
                                  ? "2px solid orange"
                                  : "",
                              };
                            }}
                            className="pb-3 group-hover:text-orange transition-colors duration-300 uppercase"
                            onClick={() => setDocTitle(tab.title)}
                          >
                            {tab.title}
                          </NavLink>
                          {tab.path === "whoviewed" &&
                            userInfo.data.whoViewed > 0 && (
                              <span className="absolute z-40 right-0 top-0 -translate-y-3/4 translate-x-3/4 bg-danger w-6 h-6 p-0.5 text-sm rounded-full max-lg:w-4 max-lg:h-4 max-lg:leading-3  max-lg:text-[8px] max-lg:-translate-y-0 max-lg:translate-x-4">
                                {userInfo.data.whoViewed}
                              </span>
                            )}
                        </li>
                      );
                    })}
                    <button
                      onClick={() => {
                        setShowModal(true);
                      }}
                      className="flex gap-2 items-center pb-4 text-lg group "
                    >
                      <span className="group-hover:text-orange transition-colors whitespace-nowrap duration-300 uppercase">
                        {t("logout")}
                      </span>
                      <FaPowerOff className="bg-danger rounded p-1" size={24} />
                    </button>

                    <Modal setShowModal={setShowModal} showModal={showModal}>
                      <div className=" text-black flex flex-col divide-y">
                        <div className="flex flex-row justify-between items-center py-2">
                          <h1 className="text-xl">{t("logout")}</h1>
                          <AiOutlineClose
                            className="cursor-pointer"
                            onClick={() => {
                              setShowModal(false);
                            }}
                          />
                        </div>
                        <div className="py-2 text-left">
                          <span className="">{t("logout_warning")}</span>
                        </div>
                        <div className="flex flex-row justify-end gap-2 pt-2">
                          <button
                            className="py-2 px-4 bg-gray-500 text-white rounded-md"
                            onClick={() => {
                              setShowModal(false);
                            }}
                          >
                            {t("close")}
                          </button>
                          <button
                            onClick={logout}
                            className="py-2 px-4 bg-danger text-white rounded-md"
                          >
                            {t("logout")}
                          </button>
                        </div>
                      </div>
                    </Modal>
                  </ul>
                </nav>
              </div>
            </div>
            {/* PROFILE PROGRESS PIC */}
            {isDashboardPage ? null : (
              <div
                id="profile-progress"
                className={`max-lg:hidden transition-all duration-300 ease-out ${
                  isScrolled ? "w-[55px] " : "w-[185px] "
                }`}
              >
                <ProgressbarWithPP user={userInfo.data} />
              </div>
            )}
          </div>
        </div>
        <div
          className={` container-full transition-all duration-500 -z-10 ease-linear ${
            isChartAvailable
              ? "bg-[linear-gradient(to_bottom,#0b1556_0%,#0b1556_80%,#fff_80%,#fff_100%)] pt-[380px]"
              : "bg-dblue pt-[410px]"
          }   max-lg:pt-[250px]`}
        >
          {/* IF CHARTS AVAILABLE, SHOW CAROUSEL */}
          {userInfo.isLoaded && isChartAvailable && (
            <div className="container p-0">
              <ChartsCarousel userInfo={userInfo.data} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
