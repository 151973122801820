import React from "react";

const OrientationCard = ({ data, loading }) => {
  // if (loading) {
  //   return (
  //     <div className="relative flex">
  //       <div class="w-12 h-12 rounded-full animate-spin border-y-4 border-solid border-blue-500 border-opacity-50 shadow-md"></div>
  //     </div>
  //   );
  // }
  return (
    <div>
      <div className="flex flex-col p-4 border-2">
        <div className="flex justify-between items-center">
          <h4 className="font-bold text-lg">{data.title}</h4>
          <span className="font-bold text-[#0d6efd] text-2xl">
            {data.rate}%
          </span>
        </div>
        <div className="relative mx-2 my-4 h-5 rounded-full bg-gray-200">
          <div
            className="h-5 rounded-full bg-gradient-to-r from-sky-200 to-[#0d6efd]"
            style={{ width: `${data.rate}%` }}
          ></div>
        </div>
        <p>{data.content}</p>
      </div>
    </div>
  );
};

export default OrientationCard;
