import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import userPP from "../user.png";

const ProgressbarWithPP = ({ user }) => {
  return (
    <CircularProgressbarWithChildren
      value={user.metaCVComletionRate}
      counterClockwise
      className={` ${user.metaCVComletionRate ? "opacity-100" : "opacity-0"}`}
      styles={buildStyles({
        textSize: "32px",
        strokeLinecap: "butt",
        width: `100%`,
        textColor: `white`,
        pathColor: `#02DF79`,
        trailColor: `#CFD1DE40`,
      })}
    >
      <div className="w-[80%] overflow-hidden rounded-full">
        <img
          src={
            user.profilePhoto
              ? `https://apibeta.humanas.io/${user.profilePhoto}`
              : userPP
          }
          className="transition-all duration-300 ease-out hover:scale-[120%] hover:brightness-95"
          alt="avatar"
        />
      </div>
    </CircularProgressbarWithChildren>
  );
};

export default ProgressbarWithPP;
