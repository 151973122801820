import React from "react";
import { SETTINGS_SECTORS, SETTINGS_WHOCANSEEMETACV } from "./constants";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="p-4 border-[1px] border-gray-100 flex flex-col gap-4 pb-8 mb-8">
        <h6 className="font-bold">Sectors</h6>
        <Select
          options={SETTINGS_SECTORS}
          placeholder="Select some options..."
          isMulti
          isSearchable
          getOptionValue={(sector) => sector.id}
          getOptionLabel={(sector) => sector.title}
          isOptionSelected={(sector) => sector.selected}
          onChange={(e) => console.log(e)}
          styles={{
            control: (styles, state) => ({
              ...styles,
              border: state.isFocused
                ? "1px solid #D6D6D6"
                : "1px solid #FFFFFF",
              borderBottom: "1px solid #D6D6D6",
              boxShadow: "none",
              padding: ".5rem",
            }),
          }}
        />
      </div>
      <div>
        <h5 className="font-bold">{t("who_can_see_my_metacv")}</h5>
        <div className="flex flex-col divide-y-[1px]">
          {SETTINGS_WHOCANSEEMETACV.map((title) => {
            return (
              <div className="py-4 flex gap-2" key={title.id}>
                <input
                  type="radio"
                  id={title.id}
                  name="whoCanSee"
                  value={title.id}
                  className="w-6 h-6 cursor-pointer appearance-none rounded-full border-gray-200 border-2 hover:bg-gray-200 checked:ring-inset checked:ring-4 checked:ring-white checked:bg-orange"
                  defaultChecked={title.selected}
                />
                <label htmlFor={title.id} className="w-full cursor-pointer">
                  {title.title}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Settings;
