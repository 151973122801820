import React from "react";

import { useStore } from "../store/useStore";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ allowedUserTypes = [1, 2], ...props }) => {
  const user = useStore((state) => state.user);

  if (!allowedUserTypes.includes(user.data.userType)) {
    return <Navigate to="/overview" />;
  }

  return <>{props.children}</>;
};
export default ProtectedRoute;
