import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useSearchParams } from "react-router-dom";
import { FaLinkedinIn } from "react-icons/fa";
import { useStore } from "../../store/useStore";

const Login = () => {
  const { initializeSession, loginRedirect, authenticated } = useStore();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  // If user comes from game, login with url params
  if (code && state) {
    loginRedirect({ code: code, state: state });
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = (formData) => {
    initializeSession(formData); //zustand
  };

  useEffect(() => {
    document.title = "Humanas | Login";
  }, []);
  // if auth is true navigate to overview
  return (
    <div className="w-[700px] max-sm:w-full m-auto flex flex-col justify-evenly shadow-xl p-12 max-sm:p-6 min-h-[900px]">
      {authenticated ? <Navigate to="/overview" /> : null}
      {/* EMPLOYEE LOGIN */}
      <div className="flex flex-col gap-4">
        <h2 className="text-3xl font-bold">Employee Login</h2>
        <p className="text-md max-sm:text-sm">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took
        </p>
        <button className="bg-linkedinBlue rounded-md text-white text-xl flex p-3">
          <FaLinkedinIn size={28} />
          <span className="w-full">Sign in with LinkedIn</span>
        </button>
      </div>
      {/* COMPANY LOGIN */}
      <div className="flex flex-col gap-4">
        <h2 className="text-3xl font-bold">Company Login</h2>
        <p className="text-md max-sm:text-sm">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry’s standard dummy text ever
          since the 1500s, when an unknown printer took
        </p>

        <form
          className="flex flex-col gap-2"
          onSubmit={handleSubmit(handleLogin)}
        >
          <label htmlFor="email" className="text-gray-500">
            Email or Phone
          </label>
          <input
            id="email"
            name="email"
            type="email"
            className="border-b border-gray-200 text-xl outline-none"
            placeholder=""
            {...register("email", { required: "Email Address is required" })}
          />
          {errors.email && (
            <div className="text-sm text-danger">{errors.email?.message}</div>
          )}
          <label htmlFor="password" className="text-gray-500">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            className="border-b border-gray-200 text-xl outline-none"
            {...register("password", { required: "Password is required" })}
          />
          {errors.password && (
            <div className="text-sm text-danger">
              {errors.password?.message}
            </div>
          )}
          <button
            type="submit"
            className="bg-dblue rounded-md text-white text-xl font-bold p-3"
          >
            LOGIN
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
