import axios from "axios";
import React, { useEffect, useState } from "react";

import AsyncSelect from "react-select/async";

const JoinForm = () => {
  const promiseOptions = (inputValue) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}/job/search`, {
        keyword: `${inputValue}`,
        data_count: "50",
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container">
      <form>
        <div className="flex flex-col gap-4">
          <label className="flex flex-col gap-1">
            Job Title
            <AsyncSelect
              placeholder="Search Job Titles..."
              cacheOptions
              loadOptions={promiseOptions}
              getOptionLabel={(e) => e.jobs_name}
              getOptionValue={(e) => e.jobs_id}
              onChange={(e) => console.log(e)}
            />
          </label>
          <label className="flex flex-col gap-1">
            Birthday
            <input
              type="date"
              className="border rounded-md py-1 px-2 border-gray-300 text-xl focus:border-gray-400"
            />
          </label>
        </div>
      </form>
    </div>
  );
};

export default JoinForm;
