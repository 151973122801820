import { create } from "zustand";
import {
  getUserInfo,
  loginCompany,
  loginRedirect,
  getPosts,
  getMetaCvFortune,
  getStats,
  getViewers,
  loginWithToken,
  searchMetaCv,
  getFilterList,
  favMetaCv,
  getFavList,
  getNeedHelp,
} from "../api/endpoints";
import { toast } from "react-toastify";

// state baslarken persist mekanigi calismali
// const getInitialState = () => {
//   const storedToken = localStorage.getItem("token");
//   if(storedToken){
//     sendAuthWithToken()
//     if(success){
//       setUser(res.data)
//     }
//     if (error) {
//       localStorage.removeItem("token")
//     }
//   }
//   return INITIAL_STATE
// }

const INITIAL_STATE = {
  user: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  userInfo: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  authenticated: false,
  posts: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  searchResult: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  fortune: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  cvViewers: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  cvStats: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  needHelp: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  favorites: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  filters: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
};

export const useStore = create((set, get) => ({
  ...INITIAL_STATE,
  initializeSession: (formData) => {
    loginCompany(
      formData,
      (data) => {
        localStorage.setItem("token", data.api_key);
        set((state) => ({
          user: { ...state.user, data: data, isLoaded: true },
          authenticated: true,
        }));
        get().fetchUserInfo();
      },
      toast.error,
      (bool) => {
        set((state) => ({ user: { ...state.user, isLoading: bool } }));
      }
    );
  },
  redirectLogin: (redirectData) => {
    loginRedirect(
      redirectData,
      (data) => {
        localStorage.setItem("token", data.api_key);
        set((state) => ({
          user: { ...state.user, data: data, isLoaded: true },
          authenticated: true,
        }));
        get().fetchUserInfo();
      },
      toast.error,
      (bool) => {
        set((state) => ({ user: { ...state.user, isLoading: bool } }));
      }
    );
  },
  persistSession: (onError) => {
    loginWithToken(
      (data) => {
        localStorage.setItem("token", data.api_key);
        set((state) => ({
          user: { ...state.user, data: data, isLoaded: true },
          authenticated: true,
        }));
        get().fetchUserInfo();
      },
      (err) => {
        localStorage.removeItem("token");
        toast.error("Token expired");
        onError();
      },
      (bool) => {
        set((state) => ({ user: { ...state.user, isLoading: bool } }));
      }
    );
  },
  logout: () => {
    localStorage.removeItem("token");
    set(INITIAL_STATE);
  },
  fetchUserInfo: () => {
    getUserInfo(
      (data) => {
        set((state) => ({
          userInfo: { ...state.userInfo, data: data, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({ userInfo: { ...state.userInfo, isLoading: bool } }));
      }
    );
  },
  fetchPosts: () => {
    getPosts(
      (data) => {
        console.log(data);
        set((state) => ({
          posts: { ...state.posts, data: data, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({ posts: { ...state.posts, isLoading: bool } }));
      }
    );
  },
  fetchFortune: () => {
    getMetaCvFortune(
      (data) => {
        set((state) => ({
          fortune: { ...state.fortune, data: data, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({ fortune: { ...state.fortune, isLoading: bool } }));
      }
    );
  },
  fetchStats: (type) => {
    getStats(
      type,
      (data) => {
        // console.log("stats", data);
        set((state) => ({
          cvStats: { ...state.cvStats, data: data, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({ cvStats: { ...state.cvStats, isLoading: bool } }));
      }
    );
  },
  fetchViewers: (type) => {
    getViewers(
      type,
      (data) => {
        console.log("viewers", data.data);
        set((state) => ({
          cvViewers: { ...state.cvViewers, isLoaded: true, data: data.data },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({
          cvViewers: { ...state.cvViewers, isLoading: bool },
        }));
      }
    );
  },

  fetchFilters: () => {
    getFilterList(
      (data) => {
        const dataArray = Object.entries(data);
        console.log("filters array", dataArray);
        set((state) => ({
          filters: { ...state.filters, data: dataArray, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({ filters: { ...state.filters, isLoading: bool } }));
      }
    );
  },

  fetchSearchResult: (filters) => {
    searchMetaCv(
      filters,
      (data) => {
        console.log("results", data);
        set((state) => ({
          searchResult: { ...state.searchResult, data: data, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({
          searchResult: { ...state.searchResult, isLoading: bool },
        }));
      }
    );
  },

  handleFavMetaCv: (id, isFav) => {
    console.log(id, isFav);
    //Optimistic update
    const firstSearchResult = get().searchResult.data;
    const newSearchResult = firstSearchResult.map((i) =>
      i.id === id ? { ...i, favourite: !isFav } : i
    );
    set((state) => ({
      searchResult: { ...state.searchResult, data: newSearchResult },
    }));
    const action = isFav ? "remove" : "add";
    //Revert update if request fails
    favMetaCv(
      id,
      action,
      (data) => {
        set((state) => ({
          searchResult: { ...state.searchResult, isLoaded: true },
        }));

        toast.success(data.message);
      },
      (err) => {
        set((state) => ({
          searchResult: { ...state.searchResult, data: firstSearchResult },
        }));
        toast.error(err);
      },
      (bool) => {
        // set((state) => ({
        //   searchResult: { ...state.searchResult, isLoading: bool },
        // }));
      }
    );
  },
  fetchFavorites: (filters) => {
    getFavList(
      filters,
      (data) => {
        set((state) => ({
          favorites: { ...state.favorites, data: data, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({
          favorites: { ...state.favorites, isLoading: bool },
        }));
      }
    );
  },
  fetchNeedHelp: () => {
    getNeedHelp(
      (data) => {
        set((state) => ({
          needHelp: { ...state.needHelp, data: data, isLoaded: true },
        }));
      },
      toast.error,
      (bool) => {
        set((state) => ({ needHelp: { ...state.needHelp, isLoading: bool } }));
      }
    );
  },
}));
