import React, { useEffect } from "react";
import Loading from "./Loading";
import { useStore } from "../store/useStore";

const PostList = () => {
  const posts = useStore((state) => state.posts);
  const fetchPosts = useStore((state) => state.fetchPosts);
  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);
  if (posts.isLoading) {
    return <Loading />;
  }
  return (
    <>
      <div className="flex flex-col gap-6">
        {posts.data.map((post) => {
          return (
            <div
              className="flex flex-col gap-2 border-[1px] border-gray-200 p-4"
              key={post.id}
            >
              <h3 className="text-3xl font-bold w-full max-lg:text-2xl">
                {post.title}
              </h3>
              <div className="flex flex-row justify-between text-gray-500">
                <span>{post.author}</span>
                <span>{post.date}</span>
              </div>
              <img
                className="w-full object-cover object-bottom"
                src={`https://api.humanas.io/${post.image}`}
                alt="Cover"
              />
              <p className="">{post.content}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default PostList;
