import React, { useState } from "react";
import DrawerBox from "../../components/DrawerBox";
import { NavLink } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import DataTable from "../../components/DataTable/DataTable";
import { AiOutlineSearch } from "react-icons/ai";
import Modal from "../../components/Modal";
import AddUserForm from "../../components/AddUserForm";
import { COMPANY_TABLE_CONFIG } from "./constants";
import { useTranslation } from "react-i18next";

const data = [
  {
    id: 1,
    name: "Ibrahim Biricik",
    manager: "Koray Atalay",
    metaCvCompletionRate: 0,
    metaCvStatus: "Baslamadi",
  },
  {
    id: 2,
    name: "Nadir Demirsoy",
    manager: "Nurettin Arkan",
    metaCvCompletionRate: 25,
    metaCvStatus: "Devam ediyor",
  },
  {
    id: 3,
    name: "Nurettin Arkan",
    manager: "Mustafa Suzmetas",
    metaCvCompletionRate: 100,
    metaCvStatus: "Tamamladi",
  },
  {
    id: 4,
    name: "Ibrahim Biricik",
    manager: "Koray Atalay",
    metaCvCompletionRate: 0,
    metaCvStatus: "Baslamadi",
  },
  {
    id: 5,
    name: "Nadir Demirsoy",
    manager: "Nurettin Arkan",
    metaCvCompletionRate: 25,
    metaCvStatus: "Devam ediyor",
  },
  {
    id: 6,
    name: "Nurettin Arkan",
    manager: "Mustafa Suzmetas",
    metaCvCompletionRate: 100,
    metaCvStatus: "Tamamladi",
  },
  {
    id: 7,
    name: "Ibrahim Biricik",
    manager: "Koray Atalay",
    metaCvCompletionRate: 0,
    metaCvStatus: "Baslamadi",
  },
  {
    id: 8,
    name: "Nadir Demirsoy",
    manager: "Nurettin Arkan",
    metaCvCompletionRate: 25,
    metaCvStatus: "Devam ediyor",
  },
  {
    id: 9,
    name: "Nurettin Arkan",
    manager: "Mustafa Suzmetas",
    metaCvCompletionRate: 100,
    metaCvStatus: "Tamamladi",
  },
  {
    id: 10,
    name: "Ibrahim Biricik",
    manager: "Koray Atalay",
    metaCvCompletionRate: 0,
    metaCvStatus: "Baslamadi",
  },
  {
    id: 11,
    name: "Nadir Demirsoy",
    manager: "Nurettin Arkan",
    metaCvCompletionRate: 25,
    metaCvStatus: "Devam ediyor",
  },
  {
    id: 12,
    name: "Nurettin Arkan",
    manager: "Mustafa Suzmetas",
    metaCvCompletionRate: 100,
    metaCvStatus: "Tamamladi",
  },
  {
    id: 13,
    name: "Ibrahim Biricik",
    manager: "Koray Atalay",
    metaCvCompletionRate: 0,
    metaCvStatus: "Baslamadi",
  },
  {
    id: 14,
    name: "Nadir Demirsoy",
    manager: "Nurettin Arkan",
    metaCvCompletionRate: 25,
    metaCvStatus: "Devam ediyor",
  },
  {
    id: 15,
    name: "Nurettin Arkan",
    manager: "Mustafa Suzmetas",
    metaCvCompletionRate: 100,
    metaCvStatus: "Tamamladi",
  },
  {
    id: 16,
    name: "Ibrahim Biricik",
    manager: "Koray Atalay",
    metaCvCompletionRate: 0,
    metaCvStatus: "Baslamadi",
  },
  {
    id: 17,
    name: "Nadir Demirsoy",
    manager: "Nurettin Arkan",
    metaCvCompletionRate: 25,
    metaCvStatus: "Devam ediyor",
  },
  {
    id: 18,
    name: "Nurettin Arkan",
    manager: "Mustafa Suzmetas",
    metaCvCompletionRate: 100,
    metaCvStatus: "Tamamladi",
  },
];

const Company = () => {
  const { t } = useTranslation();
  const [searchStr, setSearchStr] = useState("");
  const [showModal, setShowModal] = useState(false);
  return (
    <div className="container mt-4 flex flex-row gap-6 max-lg:flex-col">
      <div className="w-1/4 max-lg:w-full flex flex-col gap-4">
        <DrawerBox title="Operations" defaultState={true}>
          <NavLink
            key="1"
            to="personnel"
            style={({ isActive, isPending }) => {
              return {
                color: isActive ? "orange" : "black",
              };
            }}
            className="py-4 flex justify-between items-center"
          >
            {t("personnel_operations")}
            <FaChevronRight className="text-gray-300" />
          </NavLink>
          <NavLink
            key="2"
            to="manager"
            style={({ isActive, isPending }) => {
              return {
                color: isActive ? "orange" : "black",
              };
            }}
            className="py-4 flex justify-between items-center"
          >
            {t("manager_operations")}
            <FaChevronRight className="text-gray-300" />
          </NavLink>
        </DrawerBox>
        <DrawerBox title="Licenses" defaultState={true}>
          <div className="py-4 flex justify-between items-center">
            <h5>Total Licenses</h5>
            <span className="font-bold">1</span>
          </div>
          <div className="py-4 flex justify-between items-center">
            <h5>Used Licenses</h5>
            <span className="font-bold">2</span>
          </div>
          <div className="py-4 flex justify-between items-center">
            <h5>Remaining Licenses</h5>
            <span className="font-bold">3</span>
          </div>
        </DrawerBox>
      </div>
      <div className="w-3/4 max-lg:w-full flex flex-col gap-4">
        <div className="flex flex-row justify-between items-center max-lg:flex-col max-lg:gap-4">
          <h3 className="text-2xl font-bold uppercase">
            {t("personnel_operations")}
          </h3>
          <button
            onClick={() => setShowModal(true)}
            className="p-4 bg-orange text-white font-bold"
          >
            + {t("add_personnel")}
          </button>
          <Modal showModal={showModal} setShowModal={setShowModal}>
            <AddUserForm setShowModal={setShowModal} />
          </Modal>
        </div>
        <div className="flex">
          <input
            type="search"
            placeholder="Search"
            className="w-full border py-4 pl-6 pr-6"
            onChange={(e) => setSearchStr(e.target.value)}
            value={searchStr}
          />
          <button className="p-4 font-bold bg-gray-100">
            <AiOutlineSearch className="text-xl" />
          </button>
        </div>
        <div>
          <DataTable config={COMPANY_TABLE_CONFIG} data={data} />
        </div>
      </div>
    </div>
  );
};

export default Company;
