import React, { useState } from "react";
import { AiFillStar, AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import ProgressbarWithPP from "./ProgressbarWithPP";
import { useTranslation } from "react-i18next";

const CandidateCard = ({ metacv, handleFav }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center border-2 border-gray-100 hover:shadow-md transition-shadow py-8 px-6 max-md:p-4 max-md:flex-col max-md:gap-4">
      {/* PROFILE PROGRESS */}
      <div className="w-[150px] flex-grow-0 flex-shrink-0">
        <ProgressbarWithPP user={metacv} />
      </div>
      {/* PROFILE DETAILS */}
      <div className="flex flex-col justify-between px-4 max-md:pl-0 max-md:w-full max-xl:w-full gap-2 text-ellipsis overflow-hidden">
        <span className="font-bold text-2xl flex items-center gap-2">
          {metacv.userName}{" "}
          <span
            className={`border-[1px] rounded-md p-0.5 transition-all duration-200 ease-linear ${
              metacv.favourite ? "border-danger" : "border-gray-300"
            } `}
            onClick={() => handleFav(metacv.id, metacv.favourite)}
          >
            <AiFillStar
              className={`transition-all duration-200 ease-linear ${
                metacv.favourite ? "fill-danger" : "fill-gray-300"
              }`}
            />
          </span>
        </span>
        <span className="text-gray-500 text-lg whitespace-nowrap text-ellipsis overflow-hidden max-md:text-sm">
          {metacv.userTitle}
        </span>
        {/* Skills */}
        <span className="flex flex-wrap gap-2 font-poppins text-orange max-md:text-md">
          {metacv.skills.map((skill, index) => {
            return <span key={index}>{skill.name}</span>;
          })}
        </span>
        {/* Motivations */}
        <span className="flex gap-2 flex-wrap">
          {metacv.charts.map((chart, index) => {
            return (
              <div
                key={index}
                className="group relative px-4 py-1 rounded-full text-sm text-white font-bold max-md:px-3"
                style={{ backgroundColor: `${chart.bgColor}` }}
              >
                <span className="font-bold">{chart.rate}</span>
                {/* Tooltips */}
                <span className="absolute -top-9 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap translate-x-1/2 right-1/2 transition-all before:absolute before:w-2 before:h-2 before:bg-gray-800 before:-bottom-1 before:rotate-45 before:left-1/2 before:-ml-1">
                  {chart.title}
                </span>
              </div>
            );
          })}
        </span>
      </div>
      {/* NAVIGATE BUTTONS */}
      <div className="ml-auto flex flex-col gap-4 justify-center text-white font-bold max-md:flex-row max-md:m-0 max-md:w-full max-sm:text-xs">
        <Link
          to={`/metacv/${metacv.id}`}
          className="py-4 px-8 flex flex-row justify-center items-center gap-1 bg-orange hover:bg-blue-400 transition-all rounded-md max-md:w-1/2 whitespace-nowrap text-center"
          target="_blank"
        >
          {t("view_meta_cv")}
          <AiOutlineArrowRight size={16} strokeWidth={60} />
        </Link>
      </div>
    </div>
  );
};

export default CandidateCard;
