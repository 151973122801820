import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { HiOutlineDownload } from "react-icons/hi";
import OrientationCard from "../../components/OrientationCard";
import Loading from "../../components/Loading";
import ChartsCarousel from "../../components/ChartsCarousel";
import ProgressbarWithPP from "../../components/ProgressbarWithPP";
import { viewMetaCv } from "../../api/endpoints";

const MetaCv = () => {
  const [metaCV, setMetaCV] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  let { id } = useParams();

  useEffect(() => {
    viewMetaCv(
      id,
      (data) => {
        setMetaCV(data);
        setLoaded(true);
      },
      toast.error,
      (bool) => setLoading(bool)
    );
  }, [id]);

  if (loading) {
    return <Loading />;
  }
  if (!loaded) {
    return <Loading />;
  }

  return (
    <div className="container">
      <div className="flex py-4">
        <div className="flex flex-col flex-1 justify-center items-center gap-3">
          <span className="text-4xl font-bold">METACV</span>
          <div className="min-w-[140px] max-lg:min-w-[180px] w-[150px]">
            <ProgressbarWithPP user={metaCV} />
          </div>
          <span className="max-lg:text-4xl text-5xl font-bold">
            {metaCV.userName}
          </span>
          <span className="">{metaCV.userTitle}</span>
        </div>
        <button className="p-1 max-sm:hidden">
          <HiOutlineDownload />
        </button>
      </div>
      <ChartsCarousel userInfo={metaCV} />
      {/* Job Orientations */}
      <div className="flex gap-5 flex-col mt-4">
        {metaCV.metaCv.map((row, index) => (
          <OrientationCard key={index} data={row} />
        ))}
      </div>
    </div>
  );
};

export default MetaCv;
