import React, { useEffect } from "react";
import Layout from "./layout/Layout";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import "./App.css";

import Mymetacv from "./pages/dashboard/Mymetacv";
import Searchcv from "./pages/dashboard/Searchcv";
import Dashboard from "./pages/dashboard/Dashboard";
import WhoViewed from "./pages/dashboard/WhoViewed";
import Settings from "./pages/dashboard/Settings";
import NeedHelp from "./pages/dashboard/NeedHelp";
import Favourites from "./pages/dashboard/Favourites";

import ProtectedRoute from "./utils/ProtectedRoute";
import Company from "./pages/dashboard/Company";
import MetaCv from "./pages/dashboard/MetaCv";

import JoinForm from "./pages/dashboard/JoinForm";
import { useStore } from "./store/useStore";

function App() {
  const authenticated = useStore((state) => state.authenticated);
  const persistSession = useStore((state) => state.persistSession);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authenticated) {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        persistSession(() => navigate("/login"));
      } else {
        navigate("/login");
      }
    }
  }, [authenticated, persistSession, navigate]);
  return (
    <Routes>
      {/* <Route path="*" element={<Navigate to="/dashboard" />} /> */}
      <Route path="/login" element={<Login />} />
      <Route element={<Layout />}>
        <Route path="/*" element={<Navigate to="/overview" replace />} />
        <Route
          path="/overview"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/joinform"
          element={
            <ProtectedRoute allowedUserTypes={[1]}>
              <JoinForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cv"
          element={
            <ProtectedRoute allowedUserTypes={[1]}>
              <Mymetacv />
            </ProtectedRoute>
          }
        />
        <Route
          path="/whoviewed"
          element={
            <ProtectedRoute allowedUserTypes={[1]}>
              <WhoViewed />
            </ProtectedRoute>
          }
        />
        <Route
          path="/company"
          element={
            <ProtectedRoute allowedUserTypes={[2]}>
              <Company />
            </ProtectedRoute>
          }
        />
        <Route
          path="/favourites"
          element={
            <ProtectedRoute allowedUserTypes={[2]}>
              <Favourites />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/needhelp"
          element={
            <ProtectedRoute>
              <NeedHelp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/search"
          element={
            <ProtectedRoute allowedUserTypes={[2]}>
              <Searchcv />
            </ProtectedRoute>
          }
        />
        <Route
          path="/metacv/:id"
          element={
            <ProtectedRoute allowedUserTypes={[2]}>
              <MetaCv />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
