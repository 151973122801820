import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store/useStore";

import SearchList from "../../components/SearchList";

const Favourites = () => {
  const { t } = useTranslation();
  const fetchFavorites = useStore((state) => state.fetchFavorites);
  const favorites = useStore((state) => state.favorites);
  const [selectedFilters, setSelectedFilters] = useState({
    search: [],
    location: [],
    operation: [],
    working_status: [],
    language: [],
    education: [],
    professional_order: [],
  });

  const handleStrSearch = (e) => {
    setSelectedFilters((prev) => ({
      ...prev,
      search: [e.target.value],
    }));
  };

  useEffect(() => {
    let transformedFilters = {};
    for (const key in selectedFilters) {
      if (selectedFilters[key].length <= 0 && key !== "search") {
        transformedFilters[key] = "0";
      } else {
        transformedFilters[key] = selectedFilters[key].join();
      }
    }
    console.log("filters<>", transformedFilters);
    fetchFavorites(transformedFilters);
  }, [selectedFilters, fetchFavorites]);

  return (
    <div className="container mt-4">
      <div className="flex mb-2">
        <input
          type="search"
          placeholder={`${t("title_skill_search")}`}
          className="w-full border-[1px] py-4 px-6"
          onChange={handleStrSearch}
          value={selectedFilters.search}
        />
        <button className="p-4 font-bold bg-gray-100">
          <AiOutlineSearch className="text-xl" />
        </button>
      </div>
      <div className="flex flex-col gap-2">
        {/* {favorites.data.map((fav) => {
          return (
            <CandidateCard
              key={fav.id}
              metacv={fav}
              handleFav={favouriteService}
            />
          );
        })} */}
        <SearchList
          loading={favorites.isLoading}
          searchResult={favorites}
          searchStr={selectedFilters.search}
        />
      </div>
    </div>
  );
};

export default Favourites;
