import i18next from "../../i18n";

import { FaHandshake, FaTrashAlt } from "react-icons/fa";
import { MdPeople } from "react-icons/md";
import {
  GiBookmarklet,
  GiBookshelf,
  GiBrain,
  GiCaduceus,
  GiChart,
  GiChatBubble,
  GiCompass,
  GiCrystalBall,
  GiCurledLeaf,
  GiDramaMasks,
  GiEarthAsiaOceania,
  GiGalaxy,
  GiGearHammer,
  GiInjustice,
  GiMagnifyingGlass,
  GiMeditation,
  GiMegaphone,
  GiMicroscope,
  GiModernCity,
  GiMoneyStack,
  GiMusicalNotes,
  GiOnTarget,
  GiPalette,
  GiPencilRuler,
  GiPuzzle,
  GiRocket,
  GiRunningNinja,
  GiSportMedal,
  GiTrophiesShelf,
  GiTruck,
  GiWireframeGlobe,
  GiYinYang,
} from "react-icons/gi";
import { RiHeartsFill } from "react-icons/ri";
import { CgMathDivide } from "react-icons/cg";
import { BiEdit, BiLinkExternal } from "react-icons/bi";

export const LANGUAGES = [
  { label: "Turkish", code: "tr" },
  { label: "English", code: "en" },
];

export const SYMBOLS = {
  "Alan Yönelimi": <GiRocket size={172} className="mx-auto rotate-180" />,
  "Zeka Tipi": <GiBrain size={172} className="mx-auto" />,
  "Kişilik Tipi": <GiCrystalBall size={172} className="mx-auto" />,
};
export const ICONS = {
  "İç Uyum": <GiYinYang size={60} className="mx-auto" />,
  "Öğrenmeye Açık": <GiBookmarklet size={60} className="mx-auto" />,
  Sosyal: <MdPeople size={60} className="mx-auto" />,
  Hırslı: <GiOnTarget size={60} className="mx-auto" />,
  Tedbirli: <GiPencilRuler size={60} className="mx-auto" />,
  "Yeniliğe Açık": <GiMagnifyingGlass size={60} className="mx-auto" />,
  Uzlaşılabilir: <FaHandshake size={60} className="mx-auto" />,
  Gerçekçi: <GiModernCity size={60} className="mx-auto" />,
  Duygusal: <RiHeartsFill size={60} className="mx-auto" />,
  Sorumlu: <GiTrophiesShelf size={60} className="mx-auto" />,
  Uzamsal: <GiCompass size={60} className="mx-auto" />,
  Sözel: <GiBookshelf size={60} className="mx-auto" />,
  "Mantıksal - Matematiksel": <GiPuzzle size={60} className="mx-auto" />,
  Kinestetik: <GiRunningNinja size={60} className="mx-auto" />,
  Müziksel: <GiMusicalNotes size={60} className="mx-auto" />,
  İçsel: <GiMeditation size={60} className="mx-auto" />,
  Doğasal: <GiCurledLeaf size={60} className="mx-auto" />,
  Varoluşsal: <GiGalaxy size={60} className="mx-auto" />,
  "Sağlık Bilimleri": <GiCaduceus size={60} className="mx-auto" />,
  "Sosyal Bilimler": <GiWireframeGlobe size={60} className="mx-auto" />,
  "Dil Bilimi": <GiChatBubble size={60} className="mx-auto" />,
  İletişim: <GiMegaphone size={60} className="mx-auto" />,
  Ekonomi: <GiMoneyStack size={60} className="mx-auto" />,
  "Yönetim Bilimleri": <GiChart size={60} className="mx-auto" />,
  "Doğa Bilimleri": <GiEarthAsiaOceania size={60} className="mx-auto" />,
  Mühendislik: <GiGearHammer size={60} className="mx-auto" />,
  "Güzel Sanatlar": <GiDramaMasks size={60} className="mx-auto" />,
  "Eğitim Bilimleri": <GiMicroscope size={60} className="mx-auto" />,
  Tasarım: <GiPalette size={60} className="mx-auto" />,
  "Matematik ve Veri Bilimi": <CgMathDivide size={60} className="mx-auto" />,
  "Ulaşım ve Lojistik": <GiTruck size={60} className="mx-auto" />,
  Spor: <GiSportMedal size={60} className="mx-auto" />,
  Hukuk: <GiInjustice size={60} className="mx-auto" />,
};

export const SEARCH_FIELDS = ["userName", "userTitle", "skills"];

export const NAV_TABS = [
  { path: "overview", title: "Overview", for: [1, 2] },
  { path: "company", title: "Company", for: [2] },
  { path: "cv", title: "My Meta Cv", for: [1] },
  { path: "search", title: "Search", for: [2] },
  { path: "whoviewed", title: "Who Viewed", for: [1] },
  { path: "favourites", title: "Favourites", for: [2] },
  // { path: "settings", title: "Settings", for: [1] },
  // { path: "needhelp", title: "Need Help?", for: [1, 2] },
];
export const COMPANY_TABLE_CONFIG = [
  {
    label: "Name",
    render: (fruit) => fruit.name,
    sortValue: (fruit) => fruit.name,
  },
  {
    label: "Manager",
    render: (fruit) => fruit.manager,
    sortValue: (fruit) => fruit.manager,
  },
  {
    label: "Meta CV Completion Rate",
    render: (fruit) => (
      <div className="text-center">{fruit.metaCvCompletionRate}%</div>
    ),
    sortValue: (fruit) => fruit.metaCvCompletionRate,
  },
  {
    label: "Meta CV Status",
    render: (fruit) => (
      <div className="flex justify-end">{fruit.metaCvStatus}</div>
    ),
    sortValue: (fruit) => fruit.metaCvStatus,
  },
  {
    label: "Operations",
    render: (fruit) => (
      <div className="flex flex-row items-center justify-end">
        <button className="p-3 bg-blue-700 text-white rounded-l-md">
          <BiEdit size={20} />
        </button>
        <button className="p-3 bg-danger text-white rounded-r-md">
          <FaTrashAlt size={20} />
        </button>
      </div>
    ),
  },
];

export const WHO_VIEWED_TABLE_CONFIG = [
  {
    label: "Company",
    render: (fruit) => (
      <div className="flex flex-row items-center min-w-[220px]">
        <img
          src={fruit.logo}
          alt={fruit.company}
          className="inline-block w-20 h-20 mr-4"
        />
        {fruit.company}
      </div>
    ),
    sortValue: (fruit) => fruit.company,
    logo: (fruit) => fruit.logo,
  },
  {
    label: "Sector",
    render: (fruit) => fruit.sector,
    sortValue: (fruit) => fruit.sector,
  },
  {
    label: "Location",
    render: (fruit) => fruit.location,
    sortValue: (fruit) => fruit.location,
  },
  {
    label: "Employees",
    render: (fruit) => <div className="text-center">{fruit.employees}</div>,
    sortValue: (fruit) => fruit.employees,
  },
  {
    label: "Web Page",
    render: (fruit) => (
      <a target="_blank" href={fruit.web} rel="noopener noreferrer">
        <BiLinkExternal className="mx-auto text-orange" />
      </a>
    ),
  },
];

export const WHO_VIEWED_TYPES = [
  { type: 1, title: "Search Display" },
  { type: 2, title: "Meta CV Reviewers" },
  { type: 3, title: "Meta CV Downloaders" },
  { type: 4, title: "Enterprise Recruiter" },
  { type: 5, title: "Independent Recruiter" },
];

function updateTranslation() {
  NAV_TABS[0].title = i18next.t("overview");
  NAV_TABS[1].title = i18next.t("company");
  NAV_TABS[2].title = i18next.t("my_meta_cv");
  NAV_TABS[3].title = i18next.t("search");
  NAV_TABS[4].title = i18next.t("who_viewed");
  NAV_TABS[5].title = i18next.t("favorites");
  COMPANY_TABLE_CONFIG[0].label = i18next.t("name");
  COMPANY_TABLE_CONFIG[1].label = i18next.t("manager");
  COMPANY_TABLE_CONFIG[2].label = i18next.t("completion_rate");
  COMPANY_TABLE_CONFIG[3].label = i18next.t("metacv_status");
  COMPANY_TABLE_CONFIG[4].label = i18next.t("operations");
  WHO_VIEWED_TABLE_CONFIG[0].label = i18next.t("company");
  WHO_VIEWED_TABLE_CONFIG[1].label = i18next.t("sector");
  WHO_VIEWED_TABLE_CONFIG[2].label = i18next.t("location");
  WHO_VIEWED_TABLE_CONFIG[3].label = i18next.t("employees");
  WHO_VIEWED_TABLE_CONFIG[4].label = i18next.t("web_page");
  WHO_VIEWED_TYPES[0].title = i18next.t("search_display");
  WHO_VIEWED_TYPES[1].title = i18next.t("metacv_reviewers");
  WHO_VIEWED_TYPES[2].title = i18next.t("metacv_downloaders");
  WHO_VIEWED_TYPES[3].title = i18next.t("enterprise_recruiter");
  WHO_VIEWED_TYPES[4].title = i18next.t("independent_recruiter");
}
if (i18next.isInitialized) {
  updateTranslation();
}
i18next.on("languageChanged", function (lng) {
  updateTranslation();
});
i18next.on("loaded", function (lng) {
  updateTranslation();
});

export const USER_TABLE_DISPLAY_LIMIT = 10;

export const SETTINGS_SECTORS = [
  {
    id: 4,
    title: "Advertising and marketing",
    selected: false,
  },
  {
    id: 5,
    title: "Aerospace",
    selected: false,
  },
  {
    id: 6,
    title: "Agriculture",
    selected: false,
  },
  {
    id: 7,
    title: "Computer and technology",
    selected: false,
  },
  {
    id: 8,
    title: "Construction",
    selected: false,
  },
  {
    id: 9,
    title: "Education",
    selected: false,
  },
  {
    id: 10,
    title: "Energy",
    selected: false,
  },
  {
    id: 11,
    title: "Entertainment",
    selected: false,
  },
  {
    id: 12,
    title: "Fashion",
    selected: false,
  },
  {
    id: 13,
    title: "Finance and economic",
    selected: false,
  },
  {
    id: 14,
    title: "Food and beverage",
    selected: false,
  },
  {
    id: 15,
    title: "Health care",
    selected: false,
  },
  {
    id: 17,
    title: "Hospitality",
    selected: false,
  },
  {
    id: 18,
    title: "Manufacturing",
    selected: false,
  },
  {
    id: 19,
    title: "Media and news",
    selected: false,
  },
  {
    id: 20,
    title: "Mining",
    selected: false,
  },
  {
    id: 21,
    title: "Pharmaceutical",
    selected: false,
  },
  {
    id: 22,
    title: "Telecommunication",
    selected: false,
  },
  {
    id: 23,
    title: "Transportation",
    selected: false,
  },
];

export const SETTINGS_WHOCANSEEMETACV = [
  { title: "title1", id: "1", selected: true },
  { title: "title2", id: "2", selected: false },
  { title: "title3", id: "3", selected: false },
  { title: "title4", id: "4", selected: false },
  { title: "title5", id: "5", selected: false },
];
