import { FaChevronDown, FaChevronUp } from "react-icons/fa";

export const getIcons = (label, sortBy, sortOrder) => {
  const isActive = label === sortBy;
  if (!isActive) {
    return (
      <div>
        <FaChevronUp />
        <FaChevronDown />
      </div>
    );
  } else {
    if (sortOrder === "asc") {
      return (
        <div>
          <FaChevronUp />
        </div>
      );
    } else if (sortOrder === "desc") {
      return (
        <div>
          <FaChevronDown />
        </div>
      );
    } else if (sortOrder === null) {
      return (
        <div>
          <FaChevronUp />
          <FaChevronDown />
        </div>
      );
    }
  }
};
