import React, { Fragment, useMemo, useState } from "react";
import Pagination from "../Pagination";
import { useSort } from "../../hooks/useSort";
import { getIcons } from "./helpers/getIcons";
import { useTranslation } from "react-i18next";

const DataTable = ({ data, config }) => {
  const { t } = useTranslation();
  // SORT
  const { sortBy, sortOrder, setSortColumn, sortedData } = useSort(
    data,
    config
  );

  // PAGINATION
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return sortedData.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, pageSize, sortedData]);

  //add header to sortable columns to make it clickable and with icon
  const updatedConfig = config.map((column) => {
    if (!column.sortValue) {
      return column;
    }

    return {
      ...column,
      header: () => (
        <th
          className={`cursor-pointer p-4 uppercase ${
            column.label === "Company" && "min-w-[250px]"
          }`}
          onClick={() => setSortColumn(column.label)}
        >
          <div className="flex items-center gap-4">
            {column.label}
            <div className="text-xs">
              {getIcons(column.label, sortBy, sortOrder)}
            </div>
          </div>
        </th>
      ),
    };
  });

  // table render
  const renderedHeaders = updatedConfig.map((column) => {
    if (column.header) {
      return <Fragment key={column.label}>{column.header()}</Fragment>;
    }
    return (
      <th className="p-4 uppercase" key={column.label}>
        {column.label}
      </th>
    );
  });

  const renderedRows = currentTableData.map((rowData) => {
    const renderedCells = updatedConfig.map((column) => {
      return (
        <td key={column.label} className="p-4">
          {column.render(rowData)}
        </td>
      );
    });

    return (
      <tr className="border-b-[1px] even:bg-gray-100" key={rowData.id}>
        {renderedCells}
      </tr>
    );
  });

  return (
    <>
      <div className="overflow-x-auto mb-2">
        <table className="table-auto border-spacing-2 w-full ">
          <thead className="bg-dblue text-white whitespace-nowrap">
            <tr className="border-[1px]">{renderedHeaders}</tr>
          </thead>
          <tbody>
            {renderedRows.length > 0 ? (
              renderedRows
            ) : (
              <span className="mx-auto">{t("results_not_found")}</span>
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        dataLength={data.length}
        setPageSize={setPageSize}
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default DataTable;
