import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";

import DataTable from "../../components/DataTable/DataTable";
import DrawerBox from "../../components/DrawerBox";
import Loading from "../../components/Loading";
import { useStore } from "../../store/useStore";
import { WHO_VIEWED_TABLE_CONFIG, WHO_VIEWED_TYPES } from "./constants";

const WhoViewed = () => {
  const { t } = useTranslation();
  const [viewerType, setViewerType] = useState(1);
  const fetchStats = useStore((state) => state.fetchStats);
  const fetchViewers = useStore((state) => state.fetchViewers);
  const cvStats = useStore((state) => state.cvStats);
  const cvViewers = useStore((state) => state.cvViewers);

  useEffect(() => {
    fetchStats([{ type: `${viewerType}` }]);
    fetchViewers({ type: `${viewerType}` });
  }, [viewerType, fetchStats, fetchViewers]);

  // if (cvViewers.isLoading || cvStats.isLoading) {
  //   return <Loading />;
  // }
  // if (!cvViewers.isLoaded || !cvStats.isLoaded) {
  //   return <Loading />;
  // }
  return (
    <div className="container my-4">
      <div className="flex flex-row max-lg:flex-col gap-6">
        <div className="w-1/4 max-lg:w-full flex flex-col gap-4">
          <DrawerBox title={t("who_viewed")} defaultState={true}>
            {WHO_VIEWED_TYPES.map((type) => {
              let isActive = type.type === viewerType;
              return (
                <button
                  key={type.type}
                  onClick={() => setViewerType(type.type)}
                  className={`py-4 flex justify-between items-center ${
                    isActive ? "text-orange font-bold" : "text-black"
                  }`}
                >
                  {type.title}
                  <FaChevronRight
                    className={`${isActive ? "text-orange" : "text-gray-300"}`}
                  />
                </button>
              );
            })}
          </DrawerBox>

          {cvStats.isLoading ? (
            <Loading />
          ) : (
            <DrawerBox title={t("statistics")} defaultState={true}>
              {cvStats.data.map((stat, index) => {
                return (
                  <div
                    key={index}
                    className="flex py-4 justify-between items-center"
                  >
                    <p>{stat.title}</p>
                    <span className="font-bold">{stat.rate}</span>
                  </div>
                );
              })}
            </DrawerBox>
          )}
        </div>

        <div className="w-3/4 max-lg:w-full">
          {cvViewers.isLoading === null ? null : cvViewers.isLoading ? (
            <Loading />
          ) : (
            <DataTable
              data={cvViewers.data}
              loading={cvViewers.isLoading}
              isLoaded={cvViewers.isLoaded}
              config={WHO_VIEWED_TABLE_CONFIG}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WhoViewed;
