import React, { useState, useCallback } from "react";
import { FaChevronDown } from "react-icons/fa";

const DrawerBox = ({ title, children, defaultState }) => {
  const [isOpen, setIsOpen] = useState(
    defaultState && window.screen.width > 1024
  );

  const [height, setHeight] = useState("");

  const measuredRef = useCallback(
    (node) => {
      if (node !== null) {
        const scrollHeight = node.scrollHeight;
        setHeight(scrollHeight);
      }
    },
    [children]
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="border border-gray-200">
      <div
        className="flex justify-between items-center bg-dblue py-3 text-white px-3"
        onClick={toggle}
      >
        <p className="font-bold uppercase">{title}</p>
        <FaChevronDown
          className={`text-gray-400 transition-all duration-200  ${
            isOpen ? "rotate-180" : "rotate-0"
          }`}
        />
      </div>
      <div
        ref={measuredRef}
        className={`flex flex-col overflow-y-auto overflow-x-hidden divide-y transition-all duration-200 ease-linear ${
          isOpen ? "" : ""
        }`}
        style={{
          height: `${isOpen ? `${Math.round(height)}px` : "0px"}`,
        }}
      >
        <div className="px-4 py-2 flex flex-col divide-y-2">{children}</div>
      </div>
    </div>
  );
};

export default DrawerBox;
