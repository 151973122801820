export const BASE_URL = "https://apitest.humanas.io";

export const ENDPOINTS = {
  searchMetaCv: {
    path: "/metacv/search",
    method: "POST",
    isAuthenticated: true,
  },
  viewMetaCv: {
    path: "/metacv/recruiterview",
    method: "GET",
    isAuthenticated: true,
  },
  getUserInfo: {
    path: "/metacv/getinfo",
    method: "GET",
    isAuthenticated: true,
  },
  loginCompany: {
    path: "/login/company",
    method: "POST",
    isAuthenticated: false,
  },
  loginRedirect: {
    path: "/linkedin/login",
    method: "POST",
    isAuthenticated: false,
  },
  getPosts: {
    path: "/posts",
    method: "GET",
    isAuthenticated: true,
  },
  getMetaCvFortune: {
    path: "/metacv/fortune",
    method: "GET",
    isAuthenticated: true,
  },
  getViewers: {
    path: "/metacv/viewers",
    method: "POST",
    isAuthenticated: true,
  },
  getStats: {
    path: "/metacv/stats",
    method: "POST",
    isAuthenticated: true,
  },
  getNeedHelp: {
    path: "/needhelp",
    method: "GET",
    isAuthenticated: true,
  },
  favMetaCv: {
    path: "/company/favourite",
    method: "POST",
    isAuthenticated: true,
  },
  getFavList: {
    path: "/company/favourite",
    method: "GET",
    isAuthenticated: true,
  },
  getFilterList: {
    path: "/metacv/filters",
    method: "GET",
    isAuthenticated: true,
  },
  getOperationFields: {
    path: "/metacv/operationfields",
    method: "GET",
    isAuthenticated: true,
  },
  getEducationFields: {
    path: "/metacv/educationfields",
    method: "GET",
    isAuthenticated: true,
  },
  getWorkingStatusFields: {
    path: "/metacv/workingstatusfields",
    method: "GET",
    isAuthenticated: true,
  },
  getLocationFields: {
    path: "/metacv/locationfields",
    method: "GET",
    isAuthenticated: true,
  },
  getProfessionalOrderFields: {
    path: "/metacv/professionalorderfields",
    method: "GET",
    isAuthenticated: true,
  },
  getLanguageFields: {
    path: "/metacv/languagefields",
    method: "GET",
    isAuthenticated: true,
  },
  loginWithToken: {
    path: "/login/refreshsession",
    method: "GET",
    isAuthenticated: true,
  },
};
