import React from "react";
import Filterbox from "./Filterbox";
import Loading from "./Loading";

const Filterbar = ({
  filters,
  setSelectedFilters,
  selectedFilters,
  loading,
}) => {
  if (loading) {
    return <Loading />;
  }
  return (
    <div className="w-full flex flex-col gap-4 my-4">
      {filters.map((filter, index) => {
        return (
          <Filterbox
            key={index}
            filter={filter}
            setSelectedFilters={setSelectedFilters}
            selectedFilters={selectedFilters}
          />
        );
      })}
    </div>
  );
};

export default Filterbar;
