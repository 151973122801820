import React from "react";
import { ICONS, SYMBOLS } from "../pages/dashboard/constants";

const Chart = ({ data }) => {
  const title = data.title.split("<br>");
  const order = title[1].substring(0, title[1].indexOf(" "));
  const type = title[1].substring(title[1].indexOf(" ") + 1);
  return (
    <div
      className="mx-2 flex flex-col justify-center items-center rounded-3xl relative h-48"
      style={{
        background: `linear-gradient(${data.color}, ${data.bgColor})`,
      }}
    >
      <h3 className="text-2xl z-10 font-bold  text-white text-center">
        {order}
      </h3>
      <h4 className="text-xl z-10 font-bold  text-white text-center">{type}</h4>

      <h5 className="py-4 text-lg z-10 font-bold text-white  text-center">
        {title[0]}
        {ICONS[`${title[0]}`]}
      </h5>
      <div
        className="w-full absolute top-[0] p-2"
        style={{ color: `${data.bgColor}E0` }}
      >
        {SYMBOLS[type]}
      </div>
    </div>
  );
};

export default Chart;
