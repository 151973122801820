import { sendApiRequest, sendParallelApiRequests } from "./request_sender";

export const viewMetaCv = (
  id,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "viewMetaCv", params: { user_id: id } };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const searchMetaCv = (
  filters,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "searchMetaCv", payload: filters };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getUserInfo = (
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getUserInfo" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const loginCompany = (
  formData,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = {
    requestType: "loginCompany",
    payload: formData,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const loginWithToken = (
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = {
    requestType: "loginWithToken",
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const loginRedirect = (
  redirectData,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = {
    requestType: "loginRedirect",
    payload: redirectData,
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getPosts = (
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getPosts" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getMetaCvFortune = (
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getMetaCvFortune" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getViewers = (
  type,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getViewers", payload: type };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getStats = (
  type,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getStats", payload: type };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getNeedHelp = (
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getNeedHelp" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const favMetaCv = (
  id,
  action,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = {
    requestType: "favMetaCv",
    payload: [{ user: id, action: action }],
  };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getFavList = (
  filters,
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getFavList", params: filters };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};

export const getAllCvFields = (
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = [
    { requestType: "getLocationFields" },
    { requestType: "getOperationFields" },
    { requestType: "getEducationFields" },
    { requestType: "getWorkingStatusFields" },
    { requestType: "getProfessionalOrderFields" },
    { requestType: "getLanguageFields" },
  ];
  return sendParallelApiRequests(config, onSuccess, onError, onLoading);
};

export const getFilterList = (
  onSuccess = null,
  onError = null,
  onLoading = null
) => {
  const config = { requestType: "getFilterList" };
  return sendApiRequest(config, onSuccess, onError, onLoading);
};
