import React, { useState } from "react";
import { AiOutlineClose, AiOutlineArrowRight } from "react-icons/ai";
import { RiMenu3Fill } from "react-icons/ri";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Menu = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const closeMenu = () => {
    setIsOpen(false);
    document.body.classList.remove("overflow-hidden");
    document.body.classList.add("overflow-auto");
    console.log("closing");
  };
  const openMenu = () => {
    setIsOpen(true);
    document.body.classList.remove("overflow-auto");
    document.body.classList.add("overflow-hidden");
    console.log("opening");
  };

  return (
    <div>
      <span
        className="flex items-center font-bold text-2xl cursor-pointer hover:text-danger transition-all"
        onClick={openMenu}
      >
        <RiMenu3Fill />
        {t("hello")}!
      </span>
      <div
        className={`w-full h-full fixed inset-0 flex flex-row transition-all duration-300 ease-linear ${
          isOpen
            ? " visible opacity-100 left-0 -translate-x-[0%]"
            : " invisible opacity-0 left-0 -translate-x-[100%]"
        } bg-white z-50 overflow-y-auto overscroll-y-none max-md:flex-col-reverse`}
      >
        <div className="bg-dblue text-white w-1/3 h-screen flex flex-col justify-between max-md:justify-center p-12 max-md:w-full max-md:p-6">
          {/* Logo */}
          <svg
            className="h-[40px] fill-white max-md:hidden"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 1622.8 299.4"
          >
            <g id="a"></g>
            <g id="b">
              <g id="c">
                <g>
                  <path d="M506.9,48.4L467.6,9.1c-12.2-12.2-32-12.2-44.2,0l-39.3,39.3l32.1,32.1l29.3-29.3l29.3,29.3L506.9,48.4L506.9,48.4z" />
                  <path
                    d="M677.9,121.4H488.1c-17.2,0-31.2,14-31.2,31.2v146.8h45.5V166.9h58v83.4h45.5v-83.4h58v132.6h45.5V152.7
				C709.1,135.4,695.1,121.4,677.9,121.4L677.9,121.4z"
                  />
                  <path
                    d="M1134.8,121.4H991.5c-17.2,0-31.2,14-31.2,31.2v146.8h45.5V166.9h114.8v132.6h45.4V152.7
				C1166,135.4,1152,121.4,1134.8,121.4L1134.8,121.4z"
                  />
                  <polygon points="388.7,254 273.9,254 273.9,121.4 228.4,121.4 228.4,299.4 434.1,299.4 434.1,121.4 388.7,121.4 			" />
                  <path d="M174.5,121.4h-129V0H0v299.4h45.5V166.9h114.8v132.6h45.4V152.7C205.7,135.4,191.7,121.4,174.5,121.4L174.5,121.4z" />
                  <path
                    d="M1622.8,176.2v-23.5c0-17.2-14-31.2-31.2-31.2h-143.2c-17.2,0-31.2,14-31.2,31.2v36.6c0,16.3,12.5,29.8,28.6,31.1
				l131.7,16V254h-114.8v-14.1h-45.4v59.5h205.7v-75.6c0-16.3-12.5-29.8-28.6-31.1l-131.7-16v-9.8h114.8v9.3L1622.8,176.2
				L1622.8,176.2z"
                  />
                  <path
                    d="M906.3,121.4H763.1c-17.2,0-31.2,14-31.2,31.2v23.5h45.5v-9.3h114.8v17.8h-129c-17.2,0-31.2,14-31.2,31.2v83.6h205.7
				V152.7C937.6,135.4,923.5,121.4,906.3,121.4L906.3,121.4z M892.1,254H777.3v-23.9h114.8V254z"
                  />
                  <path
                    d="M1363.2,121.4H1220c-17.2,0-31.2,14-31.2,31.2v23.5h45.4v-9.3H1349v17.8h-129c-17.2,0-31.2,14-31.2,31.2v83.6h205.7
				V152.7C1394.4,135.4,1380.4,121.4,1363.2,121.4L1363.2,121.4z M1349,254h-114.8v-23.9H1349L1349,254L1349,254z"
                  />
                </g>
              </g>
            </g>
          </svg>
          <div className="flex flex-col gap-4">
            <h2 className="text-4xl max-md:text-3xl">{t("welcome")}</h2>
            <h3 className="text-3xl max-md:text-2xl">
              {t("discover_potential")}
            </h3>
            <button
              type="submit"
              className="bg-white rounded-md text-dblue text-xl font-bold p-3 max-md:text-base border transition-all border-dblue hover:bg-dblue hover:text-white hover:border-white"
            >
              {t("learn_more")}
            </button>
          </div>
        </div>
        <div className="w-2/3 h-screen flex flex-col gap-4 justify-between p-12 max-md:w-full max-md:p-6">
          <button
            type="button"
            onClick={closeMenu}
            className="px-6 py-3 ml-auto font-bold flex items-center max-md:p-3 text-danger text-2xl"
          >
            <AiOutlineClose size={28} strokeWidth={50} />
            <span>{t("close")}</span>
          </button>

          <ul className="flex flex-col gap-4 text-3xl text-dblue font-bold max-md:text-lg max-md:py-4 max-md:gap-2">
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/homepage"
              >
                {t("homepage")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/about-humanas"
              >
                {t("about")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/team"
              >
                {t("team")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/community"
              >
                {t("community")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/news"
              >
                {t("news")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/solutions"
              >
                {t("solutions")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/case-studies"
              >
                {t("studies")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/information/meta-cv/2"
              >
                {t("meta_cv")}
              </a>
            </li>
            <li className="before:border-b-2 before:border-dblue before:inline-block before:w-6 before:mr-2 hover:text-danger hover:before:border-danger transition-all">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/contact"
              >
                {t("contact")}
              </a>
            </li>
          </ul>

          <div className="flex flex-row justify-between text-dblue max-md:flex-col items-start gap-6">
            <div className="font-bold text-lg">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://humanas.io/join-us"
                className="flex flex-row items-baseline gap-2 hover:text-danger transition-all"
              >
                {t("join_us")}
                <AiOutlineArrowRight strokeWidth={100} />
              </a>
            </div>
            <div className="flex flex-col gap-4 items-end text-lg">
              <ul className="flex gap-6">
                <li>
                  <a href="#">
                    <FaFacebookF />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaInstagram />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaYoutube />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <FaLinkedin />
                  </a>
                </li>
              </ul>
              <ul className="flex gap-6 max-md:hidden">
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://humanas.io/protection-of-personal-data"
                    className="hover:text-danger transition-all"
                  >
                    {t("protection_of_personal_data")}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://humanas.io/privacy-policy"
                    className="hover:text-danger transition-all"
                  >
                    {t("privacy_policy")}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://humanas.io/cookie-policy"
                    className="hover:text-danger transition-all"
                  >
                    {t("cookie_policy")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
