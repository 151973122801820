import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useStore } from "../store/useStore";

const Layout = () => {
  const user = useStore((state) => state.user);
  const authenticated = useStore((state) => state.authenticated);
  const logout = useStore((state) => state.logout);
  const userInfo = useStore((state) => state.userInfo);

  if (!authenticated) {
    return null;
  }

  return (
    <>
      <TopScroller />
      <Header user={user} userInfo={userInfo} logout={logout} />
      <Outlet />
      <Footer />
    </>
  );
};

const TopScroller = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export default Layout;
