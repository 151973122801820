import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import DrawerBox from "./DrawerBox";

const Filterbox = ({ filter, setSelectedFilters, selectedFilters }) => {
  const [searchStr, setSearchStr] = useState("");

  const toggleElement = (arr, val) =>
    arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val];
  return (
    <DrawerBox
      title={filter[1].name}
      defaultState={["location", "operation"].includes(filter[0])}
    >
      {/* Search Filters Input*/}
      <div className="flex relative">
        <AiOutlineSearch className="absolute top-3 left-2 text-lg text-gray-400" />
        <input
          type="text"
          placeholder={`Search ${filter[1].name}`}
          className="p-2 pl-8 border-[1px] border-gray-200 focus-visible:outline-none focus:border-gray-300 w-full"
          onChange={(e) => setSearchStr(e.target.value)}
        />
      </div>
      {filter[1].data
        .filter((item) =>
          item.title.toLowerCase().includes(searchStr.toLowerCase())
        )
        .map((item, index) => {
          return (
            <div
              key={index}
              className="flex items-center justify-left py-3 text-gray-800 border-gray-200 relative"
            >
              <input
                id={item.title}
                value={item.id}
                type="checkbox"
                checked={selectedFilters[filter[0]].includes(`${item.id}`)}
                onChange={(e) => {
                  console.log(e.target.value, e.target.checked);
                  setSelectedFilters((prev) => ({
                    ...prev,
                    [filter[0]]: toggleElement(prev[filter[0]], e.target.value),
                  }));
                }}
                className="w-4 h-4 border-[1px] appearance-none outline-gray-200 peer"
              />
              <label className="pl-3" htmlFor={item.title}>
                {item.title}
              </label>
              <svg
                className="
                    absolute 
                    w-3 h-3 ml-0.5
                    hidden peer-checked:block
                    pointer-events-none"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="orange"
                strokeWidth="4"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12"></polyline>
              </svg>
            </div>
          );
        })}
    </DrawerBox>
  );
};

export default Filterbox;
