import React from "react";

const Loading = () => {
  return (
    <div className="relative flex items-center justify-center h-[50vh]">
      <div className="w-12 h-12 rounded-full animate-spin border-y-4 border-solid border-blue-500 border-opacity-50 shadow-md"></div>
    </div>
  );
};

export default Loading;
