import React from "react";
import Slider from "react-slick";
import Chart from "./Chart";

const ChartsCarousel = ({ userInfo }) => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    centerMode: false,
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1850,
        settings: {
          slidesToShow: 6,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="my-2">
      {userInfo &&
        userInfo.charts.map((chart, index) => {
          return <Chart key={chart.id} data={chart} />;
        })}
    </Slider>
  );
};

export default ChartsCarousel;
